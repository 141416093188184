html {
  height: 100%;
}

body {
  margin: 0;
  transition: opacity ease-in 0.2s;
  font-family: Roboto, sans-serif;
  background: rgb(76, 76, 76);
  background: -moz-linear-gradient(-45deg, rgba(76, 76, 76, 1) 0%, rgba(89, 89, 89, 1) 0%, rgba(102, 102, 102, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(71, 71, 71, 1) 0%, rgba(44, 44, 44, 1) 0%, rgba(17, 17, 17, 1) 0%, rgba(43, 43, 43, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(76, 76, 76, 1) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(76, 76, 76, 1) 0%, rgba(89, 89, 89, 1) 0%, rgba(102, 102, 102, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(71, 71, 71, 1) 0%, rgba(44, 44, 44, 1) 0%, rgba(17, 17, 17, 1) 0%, rgba(43, 43, 43, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(76, 76, 76, 1) 100%);
  background: linear-gradient(135deg, rgba(76, 76, 76, 1) 0%, rgba(89, 89, 89, 1) 0%, rgba(102, 102, 102, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(71, 71, 71, 1) 0%, rgba(44, 44, 44, 1) 0%, rgba(17, 17, 17, 1) 0%, rgba(43, 43, 43, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(76, 76, 76, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#4c4c4c', GradientType=1);
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
}

#header {
  position: fixed;
  background-color: transparent;
  width: 100%;
}

.container>div:last-child {
  flex: 1;
}

#outlet>.leaving {
  animation: 2s fadeOut ease-in-out;
}

#outlet>.entering {
  animation: 0.1s fadeIn ease-in-out;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

